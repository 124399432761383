import { useEffect, useState } from "react";
import logo from "../images/logo.svg";
import logoTwo from "../images/logo-two.png";
import { b2cPolicies } from "../utils/msal/config";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";

const MainHeader = () => {
  const [expanded, setExpanded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  let AzureADB2C = JSON.parse(localStorage.getItem("AzureADB2C")) || {};

  useEffect(() => {
    setAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  const login = async () => {
    setExpanded(false);
    if (!isAuthenticated) {
      instance.loginRedirect()
        .then(result => {
          AzureADB2C = {
            aadUserId: result.uniqueId,
            firstName: result.idTokenClaims.given_name,
            lastName: result.idTokenClaims.family_name,
            email: result.idTokenClaims.emails[0],
            phone: "",
          };
          localStorage.setItem("AzureADB2C", JSON.stringify(AzureADB2C));
        })
    }
  };
  const logout = async () => {
    setExpanded(false);
    localStorage.removeItem("AzureADB2C");
    localStorage.removeItem("customerDetails");

    navigate("/");

    await instance.logout();
  };
  const onSelectNav = () => {
    setExpanded(false);
  }
  return (
    <>
      <header className="main-header ">
        <div className="container">
          <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
            <Container fluid className="p-0">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div>
                  <div className="logo">
                    <img src={logo} alt="img" />
                    <img className="logo-2" src={logoTwo} alt="img" />
                  </div>
                </div>
                <div>
                  <Navbar.Toggle aria-controls="navbarScroll" />
                  <Navbar.Collapse id="navbarScroll">
                    <Nav
                      className="me-auto my-2 my-lg-0"
                      style={{ maxHeight: "100px" }}
                      navbarScroll
                    >
                      <nav className="nav-box">
                        <ul>
                          <li className="d-block" onClick={onSelectNav}>
                            {" "}
                            <Link to="/">Home</Link>
                          </li>
                          {authenticated && <li className="d-block" onClick={onSelectNav}>
                            <Link to="/Applications">
                              My Applications
                            </Link>
                          </li>}
                          {!authenticated && (
                            <li className="d-block" onClick={login}>
                              Sign in / Register
                            </li>
                          )}
                          {authenticated && (
                            <Dropdown className="nav-dropdown">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                My Documents
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <li onClick={onSelectNav}>
                                  <Link to="/RiskAssessmentsList">
                                    {" "}
                                    Risk Assessments
                                  </Link>
                                </li>
                                <li onClick={onSelectNav}>
                                  <Link to="/TransportLettersList">
                                    {" "}
                                    Transport Letters
                                  </Link>
                                </li>
                                <li onClick={onSelectNav}>
                                  <Link to="/TransportAgreementsList">
                                    {" "}
                                    Transport Agreements
                                  </Link>
                                </li>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                          {authenticated && (
                            <Dropdown className="nav-dropdown">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                Profile
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <li onClick={onSelectNav}>
                                  <Link to="/Profile"> Profile Settings</Link>
                                </li>
                                <li onClick={logout}>Log out</li>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </ul>
                      </nav>
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </div>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default MainHeader;
