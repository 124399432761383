import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { updateRiskAssessment } from "../../services/Index";

import { RotatingLines } from "react-loader-spinner";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RiskAssessment = () => {
  let navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const { from } = location.state;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [])
  const handleChange = (e) => {
    e.preventDefault();

    setComment(e.target.value);
  };
  const handleSubmit = async () => {
    if (comment.length !== 0) {
      const data = {
        riskAssessmentId: from.riskAssessmentId,
        comments: comment,
      };
      setLoading(true);
      await updateRiskAssessment(data);
      setLoading(false);
      toast.success("Your comment has been submitted successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/RiskAssessmentsList");
    } else {
      setError(true);
    }
  };
  return (
    <div className="riskassessment-footer">
      {loading && (
        <div className="center">
          <RotatingLines
            strokeColor="#4e3782"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <div className="container">
        <div className="card mb-3 mt-4">
          <div className="card-header">
            <h5>Request Details (Ref: {from && from.riskAssessmentNumber})</h5>
          </div>
          <div className="card-body">
            <section className="mb-3">
              <h5 className="border-bottom pt-0">Child / Young Person</h5>
              <div className="row ">
                <div className="col-md-4 mb-3">
                  <strong>Name</strong>

                  <Form.Control
                    type="text"
                    placeholder="Name"
                    disabled
                    value={from && from.tR_Customer_Student.studentFullName}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <strong>Sex</strong>
                  <Form.Control
                    type="text"
                    placeholder="Sex"
                    disabled
                    value={from && from.tR_Customer_Student.studentSex}
                  />
                </div>
                <div className="col-md-4">
                  <strong>Date of birth</strong>

                  <Form.Control
                    type="text"
                    placeholder="Date of Birth"
                    disabled
                    value={
                      from &&
                      new Date(
                        from.tR_Customer_Student.studentDateOfBirth
                      ).toLocaleDateString("en-GB")
                    }
                  />
                </div>
              </div>
            </section>
            <section>
              <h5 className=" border-bottom">Parent / Carer</h5>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <strong>Name</strong>

                  <Form.Control
                    type="text"
                    placeholder="Name"
                    disabled
                    value={from && from.tR_Customer_Student.customerFullName}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <strong>Telephone</strong>

                  <Form.Control
                    type="tel"
                    placeholder="Phone Number"
                    disabled
                    value={from && from.tR_Customer_Student.customerTelephone}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <strong>Address</strong>

                  <Form.Control
                    type="text"
                    placeholder="Address"
                    disabled
                    value={from && from.tR_Customer_Student.customerAddress}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="card border-primary mb-3">
          <div className="card-body">
            <div className="row">
              <Form>
                <div className="row">
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Assessment Type</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={from && from.type}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Completed by</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={from && from.completedBy}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Child / Young Person Height</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={from && from.studentHeightCm}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Child / Young Person Weight</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={from && from.studentWeightKg}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>
                        Relevant Medical/Mobility Conditions
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        disabled
                        value={from && from.relevantMedicalOrMobilityConditions}
                      />
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <div className="d-flex ">
                      <label>Was The Child / Young person Seen?</label>
                      <Form.Check
                        className="mx-3"
                        type="radio"
                        label="Yes"
                        checked={from && from.wasStudentSeen === true}
                      />
                      <Form.Check
                        className="mx-3"
                        type="radio"
                        label="No"
                        checked={from && from.wasStudentSeen === false}
                      />
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </div>
            {/* <hr /> */}
            {/* <div className="row">
              <div className="col-md-3">
                <strong>Relevant medical/mobility conditions</strong>
              </div>
              <div className="col-md-9">
                @Model.Data.RelevantMedicalOrMobilityConditions
              </div>
            </div> */}
          </div>
        </div>

        <div className="card border-warning mb-3">
          <div className="card-header">
            <h5>Parental Responsibilities and Safeguarding</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">
                <p>
                  Do you, a family member or childminder have the facility to
                  take your child to school in their/your car?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentHaveFacilityTransportStudentInCar === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentHaveFacilityTransportStudentInCar === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentHaveFacilityTransportStudentInCarDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  If transport assistance is provided in the form of a vehicle.
                  Can child / young person be accepted from a vehicle by another
                  member of the family?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentCanStdBeAcceptedByFamilyMember === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentCanStdBeAcceptedByFamilyMember === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentCanStdBeAcceptedByFamilyMemberDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is parent aware to be at home within 10 minutes from school
                  finishing time?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentAtHomeTenMinsOfSchoolFinishTime === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentAtHomeTenMinsOfSchoolFinishTime === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentAtHomeTenMinsOfSchoolFinishTimeDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is parent aware of pick up time window? (i.e. 7.45 – 9.00 ) Up
                  to 45 minutes primary, 75 minutes secondary.
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.parentAwareOfPickupTimeWindow === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.parentAwareOfPickupTimeWindow === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentAwareOfPickupTimeWindowDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is parent aware that they must take the child / young person
                  to and from the vehicle?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentalResponsibilitiesIsParentAwareMustTakeStudentToFromVehicle ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentalResponsibilitiesIsParentAwareMustTakeStudentToFromVehicle ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.parentalResponsibilitiesIsParentAwareMustTakeStudentToFromVehicleDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-danger mb-3">
          <div className="card-header">
            <h5>Child / Young Person Information</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="row justify-content-between align-items-center mb-3">
                <div className="col-md-2">School/College Start Time</div>
                <div className="col-md-4">
                  <Form.Control
                    type="text"
                    placeholder="School Start Time"
                    disabled
                    value={from.schoolOrCollegeStartTimeHour + ":" + from.schoolOrCollegeStartTimeMinutes}
                  />
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                  School/College End Time
                </div>
                <div className="col-md-4">
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="School End Time"
                    disabled
                    value={from.schoolOrCollegeEndTimeHour + ":" + from.schoolOrCollegeEndTimeMinutes}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <p>
                  If traveling in a vehicle, will the child / young person
                  potentially try and take their seatbelt off before they
                  should?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationRemoveSeatBelt === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.studentInformationRemoveSeatBelt === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationRemoveSeatBeltDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Will the child / young person try to get out of their seat? If
                  Yes how do you prevent this from happening?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationGetOutOfSeat === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.studentInformationGetOutOfSeat === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationGetOutOfSeatDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Can the child / young person travel on public transport alone?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationTravelAlone === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.studentInformationTravelAlone === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationTravelAloneDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Can the child / young person ride a bike?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationRideABike === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.studentInformationRideABike === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationRideABikeDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is the child / young person mobile and can walk? Is this
                  limited to short distances?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationIsMobileAndCanWalk === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationIsMobileAndCanWalk === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationIsMobileAndCanWalkDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Does the child / young person have an understanding of road
                  safety?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationUnderstandRoadSafety === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationUnderstandRoadSafety === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationUnderstandRoadSafetyDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is the child / young person unpredictable? E.g. do they have
                  mood swings, anxious?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationIsUnpredictable === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.studentInformationIsUnpredictable === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationIsUnpredictableDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is the child / young person capable of sharing transport with
                  other passengers?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationWillShareTransport === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationWillShareTransport === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationWillShareTransportDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Does the child / young person display challenging behaviour?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationChallengingBehaviour === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationChallengingBehaviour === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationChallengingBehaviourDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are there any distraction/calming techniques to use?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationCalmingTechniques === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationCalmingTechniques === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationCalmingTechniquesDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Is the child / young person in pain when moving?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.studentInformationInPainWhenMoving === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationInPainWhenMoving === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationInPainWhenMovingDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Are the students senses impaired (sight, hearing, speech)?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationSensoryImpairment === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationSensoryImpairment === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationSensoryImpairmentDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Can the child / young person communicate their needs clearly?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationCanCommunicateNeeds === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationCanCommunicateNeeds === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationCanCommunicateNeedsDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Can the child / young person use/understand Makaton?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationCanUnderstandMakaton === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationCanUnderstandMakaton === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationCanUnderstandMakatonDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Does the child / young person use any other methods to
                  communicate?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationHasOtherCommunicationMethod ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationHasOtherCommunicationMethod ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.studentInformationHasOtherCommunicationMethodDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Does the child / young person need medication or use oxygen?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationRequiredMedicationOrOxygen ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationRequiredMedicationOrOxygen ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.studentInformationRequiredMedicationOrOxygenDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Does the child / young person experience seizures?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.seizuresHasSeizures === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.seizuresHasSeizures === false}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            {/* @if (Model.Data.HasSeizures.GetValueOrDefault())
            { */}
            <hr />

            <div className="row justify-content-between align-items-center mb-3">
              <div className="col-md-2 fw-bold">Seizure type</div>
              <div className="col-md-4">
                <Form.Control
                  type="text"
                  placeholder="Seizure type"
                  disabled
                  value={from.seizuresType}
                />
              </div>
              <div className="col-md-2 fw-bold d-flex justify-content-end">
                Seizure signs
              </div>
              <div className="col-md-4">
                {" "}
                <Form.Control type="text" disabled value={from.seizuresSigns} />
              </div>
            </div>

            <div className="row justify-content-between align-items-center mb-3">
              <div className="col-md-2 fw-bold">Seizure frequency</div>
              <div className="col-md-4">
                <Form.Control
                  type="text"
                  disabled
                  value={from.seizuresFrequency}
                />
              </div>
              <div className="col-md-2 fw-bold d-flex justify-content-end">
                Seizure treatment
              </div>
              <div className="col-md-4 mb-3">
                {" "}
                <Form.Control
                  type="text"
                  disabled
                  value={from.seizuresTreatment}
                />
              </div>
            </div>

            <div className="row"></div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Are you aware that in an event of a seizure our drivers are
                  expected to stop and call emergency services and are Not
                  permitted to administrate any medication?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.seizuresIsAwareStopAndCallEmergencyServices ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.seizuresIsAwareStopAndCallEmergencyServices ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.seizuresIsAwareStopAndCallEmergencyServicesDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Is there any other relevant information (medical or otherwise)
                  about the pupil that has Not been covered above?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.studentInformationAnyOtherInformation === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.studentInformationAnyOtherInformation === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationAnyOtherInformationDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row justify-content-between d-flex">
              <div className="col-md-6 fw-bold">
                Strategies to help pupil including favourite
                activities/character
              </div>
              <div className="col-md-5 ">
                {" "}
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInformationAssistanceStrategies}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <p>
                    If travelling in a vehicle is a 5 Point harness used?
                  </p>
                </div>
                <div className="col-md-2">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <div className="d-flex">
                      <Form.Check
                        className="mx-3"
                        type="radio"
                        label="Yes"
                        checked={from.fivePointHarness === true}
                      />
                      <Form.Check
                        type="radio"
                        label="No"
                        checked={from.fivePointHarness === false}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-5">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      disabled
                      value={from.fivePointHarnessDetail}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-primary mb-3">
          <div className="card-header">
            <h5>Independent Travel</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">
                <p>
                  Can the young person travel on public transport alone if they undertake independent travel training?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.independentTravel === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.independentTravel === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.independentTravelDetail}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-info mb-3">
          <div className="card-header">
            <h5>Mobility Aids</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">
                <p>Wheelchair?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsHasWheelChair === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsHasWheelChair === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsHasWheelChairDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <p>Wheelchair assessed and added to passport scheme?</p>
            <div className="row">
              {/* <div className="col-md-5">
                <p>Wheelchair?</p>
              </div> */}
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsWheelChairAssessed === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsWheelChairAssessed === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsWheelChairAssessedDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <p>
              Wheelchair has been researched and has passed the criteria for
              travel?
            </p>
            <div className="row">
              {/* <div className="col-md-5">
                <p>Wheelchair?</p>
              </div> */}
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.mobilityAidsWheelChairPassedTravelCriteria === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.mobilityAidsWheelChairPassedTravelCriteria ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.mobilityAidsWheelChairPassedTravelCriteriaDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Wheelchair transfer</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsWheelChairTransfer === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsWheelChairTransfer === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsWheelChairTransferDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Walking frame</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsHasWalkingFrame === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsHasWalkingFrame === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsHasWalkingFrameDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Baby seat</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsHasBabySeat === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsHasBabySeat === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsHasBabySeatDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Booster seat</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.mobilityAidsHasBoosterSeat === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.mobilityAidsHasBoosterSeat === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.mobilityAidsHasBoosterSeatDetail}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-success mb-3">
          <div className="card-header">
            <h5>Outside Environment</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">
                <p>Can vehicle be seen from property door?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentCanVehicleBeSeenFromPropertyDoor ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentCanVehicleBeSeenFromPropertyDoor ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentCanVehicleBeSeenFromPropertyDoorDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Can vehicle park safely to load/unload?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentCanVehicleParkSafely === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentCanVehicleParkSafely === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.outsideEnvironmentCanVehicleParkSafelyDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Does the vehicle have to reverse to the property?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentDoesVehicleHaveToReverse === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentDoesVehicleHaveToReverse ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentDoesVehicleHaveToReverseDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Can pupil get onto the bus from the pavement?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentCanStudentGetOnBusFromPavement ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentCanStudentGetOnBusFromPavement ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentCanStudentGetOnBusFromPavementDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Can pupil alight from the bus onto the pavement?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentCanStudentAlightBusFromPavement ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentCanStudentAlightBusFromPavement ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentCanStudentAlightBusFromPavementDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Are there any surrounding obstructions for parking e.g. yellow
                  lines, bend in road etc
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentAreThereSurroundingObstructions ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentAreThereSurroundingObstructions ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentAreThereSurroundingObstructionsDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Does the rear ramp/lift need deploying for mounting the
                  vehicle?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentLiftRampRequiredForMountingVehicle ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentLiftRampRequiredForMountingVehicle ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentLiftRampRequiredForMountingVehicleDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Does the rear ramp/lift need deploying for alighting?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.outsideEnvironmentLiftRampRequiredForDismountingVehicle ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.outsideEnvironmentLiftRampRequiredForDismountingVehicle ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from.outsideEnvironmentLiftRampRequiredForDismountingVehicleDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        {/* @if (Model.Data.HasWheelChair.GetValueOrDefault()) { */}
        <div className="card border-warning mb-3">
          <div className="card-header">
            <h5>Wheelchairs</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 fw-bold">Type</div>
                  <div className="col-md-9">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control disabled value={from.wheelchairType} />
                    </Form.Group>
                  </div>
                </div>
                {/* @if (Model.Data.WheelChairType == WheelchairType.Powered)
                            { */}
                <div className="row">
                  <div className="col-md-3 fw-bold">Manoeuvrability</div>
                  <div className="col-md-9">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control
                        disabled
                        value={from.wheelchairManoeuvrability}
                      />
                    </Form.Group>
                  </div>
                </div>
                {/* } */}
                <div className="row">
                  <div className="col-md-3 fw-bold">Serial No</div>
                  <div className="col-md-9">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control disabled value={from.wheelchairSerialNo} />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 fw-bold d-flex justify-content-end">
                    Make
                  </div>
                  <div className="col-md-9">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control disabled value={from.wheelchairMake} />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 fw-bold d-flex justify-content-end">
                    Model
                  </div>
                  <div className="col-md-9">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control disabled value={from.wheelchairModel} />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are karabiner symbols visible?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairKarabinerSymbolsVisible === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairKarabinerSymbolsVisible === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    disabled
                    as="textarea"
                    rows={2}
                    value={from.wheelchairKarabinerSymbolsVisibleDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are anchorage brakes fitted?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairAnchorageBrakesFitted === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairAnchorageBrakesFitted === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    disabled
                    as="textarea"
                    rows={2}
                    value={from.wheelchairAnchorageBrakesFittedDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are there any trays that need removing?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairTraysNeedRemoving === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairTraysNeedRemoving === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairTraysNeedRemovingDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Does any equipment need removing i.e. communication aid?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.wheelchairOtherEquipmentNeedRemoving === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.wheelchairOtherEquipmentNeedRemoving === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairOtherEquipmentNeedRemovingDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are there any head restraints fitted?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairHeadRestraints === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairHeadRestraints === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairHeadRestraintsDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Are there any leg extensions?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairLegExtensions === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairLegExtensions === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairLegExtensionsDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            {/* @if (Model.Data.WheelchairLegExtensions.GetValueOrDefault())
            { */}
            <div className="row">
              <div className="col-md-5">
                <p>Are the leg extensions adjustable?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairLegExtensionsAdjustable === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairLegExtensionsAdjustable === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairLegExtensionsAdjustableDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            {/* } */}

            <div className="row">
              <div className="col-md-5">
                <p>Does the W /C have a moulded seat?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairHasMouldedSeat === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairHasMouldedSeat === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairHasMouldedSeatDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>Is a lap belt fitted?</p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairLapBeltFitted === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairLapBeltFitted === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairLapBeltFittedDetail}
                  />
                </Form.Group>
              </div>
            </div>

            {/* @if (!Model.Data.WheelchairLapBeltFitted.GetValueOrDefault())
            {
                    @Html.Alert("Inform client that they are unable to use the W/C for transfer or travel until a lap belt has been fitted.", BootstrapType.danger)
                } */}
            <hr />

            {/* @if (Model.Data.WheelChairType == WheelchairType.Powered)
            { */}

            <div className="row">
              <div className="col-md-5">
                <p>
                  Can the passenger be allowed to drive the wheelchair on to the
                  vehicle?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.wheelchairPassengerPermittedtoDriveVehicle === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.wheelchairPassengerPermittedtoDriveVehicle ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    disabled
                    rows={2}
                    value={
                      from.wheelchairPassengerPermittedtoDriveVehicleDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-5">
                <p>
                  Can the carer be allowed to drive the wheelchair on to the
                  vehicle?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.wheelchairCarerPermittedToDriveVehicle === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.wheelchairCarerPermittedToDriveVehicle === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    disabled
                    as="textarea"
                    rows={2}
                    value={from.wheelchairCarerPermittedToDriveVehicleDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            {/* } */}

            <div className="row">
              <div className="col-md-5">
                <p>
                  Has the passenger /carer signed the indemnity letter relating
                  to the use of the wheelchair whilst on the vehicle?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairIndemnityLetterSigned === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairIndemnityLetterSigned === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairIndemnityLetterSignedDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-3 fw-bold">What will clamp the front?</div>
              <div className="col-md-4 ">
                <p className="fw-bold">D Strap required for front?</p>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairDStrapRequiredFront === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairDStrapRequiredFront === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairFrontClamp}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-3 fw-bold">What will clamp the back?</div>
              <div className="col-md-4">
                <p className="fw-bold">D Strap required for back?</p>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.wheelchairDStrapRequiredRear === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.wheelchairDStrapRequiredRear === false}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairRearClamp}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-3 fw-bold">
                Any pictures taken of the W/C?
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.wheelchairPhotos}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-success mb-3">
          <div className="card-header">
            <h5>Additional Information</h5>
          </div>
          <div className="card-body">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <p>
                  Please provide the medical condition/s and or behaviour that
                  the child / young person has and how this may impact on the
                  journey to and from school:
                </p>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from && from.medicalConditioStudentImpactJourneyAndSchool
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row justify-content-between">
              <div className="col-md-6">
                <p>
                  Please provide the medical condition/s that the applicant /
                  adult has and how this may impact on the journey to and from
                  school/college:
                </p>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    justify-content-betweens={2}
                    disabled
                    value={from && from.medicalConditionApplicantImpactJourneyToFrom}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row justify-content-between">
              <div className="col-md-6">
                <p>
                  Are there any other medical conditions we need to be aware of?
                </p>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from && from.otherMedicalConditionsWeNeedtobeAwareOf}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  If travelling in a vehicle is a cumberband used (Responsible
                  adult must agree to this Y/N)?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.stdInforTravelInVehicleisaCumberBandUsed === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.stdInforTravelInVehicleisaCumberBandUsed === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.stdInfoTravelInVehcleCumberBandUsedDetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Does the child / young person have medication for seizures?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from &&
                        from.stdInfoDoesTheStdHaveMedicForSeizures === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from &&
                        from.stdInfoDoesTheStdHaveMedicForSeizures === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from && from.stdInfoDoesTheStdHaveMedicForSeizuresDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Does the child / young person have medication for seizures?
                  I.e., midazolam, if yes please explain that there are limited
                  numbers of staff/contracts that are trained to administer
                  midazolam
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from &&
                        from.studentInfoStdMedicationForSeizuresMidazolam ===
                        true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from &&
                        from.studentInfoStdMedicationForSeizuresMidazolam ===
                        false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from &&
                      from.studentInfoStdMedicationForSeizuresMidazolamDetail
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-6">
                <p>
                  What my child / young person like to do on the way to
                  school/college
                </p>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInfoStudentOnTheWayToSchoolCollege}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  What might make my child / young person unhappy on the way to
                  school/ college
                </p>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={
                      from && from.studentInfoStudentUnhappyToSchoolCollege
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  What helps calm my child / young person when they are upset
                  is?
                </p>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInfoHelpCalmChildStudentWhenHeUpset}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Additional information – please add any information you feel
                  will help the driver and your child’s/ student’s journey as
                  comfortable as it can be
                </p>
              </div>
              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.studentInfoHelpDriverStdJourneyComfortable}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  If over the age of 18 is the child / young person classed as a
                  vulnerable adult that would require being handed over to a
                  responsible adult?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.parentStdOverAge18HandoverToAdult === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.parentStdOverAge18HandoverToAdult === false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentStdOverAge18HandoverToAdultDetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Does the child / young person have any communication,
                  behavioural or medical needs that would mean that they were
                  not able to travel without a passenger assistant?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.parentStdCommBehaviorMedicalWithoutPA === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentStdCommBehaviorMedicalWithoutPA === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentStdCommBehaviorMedicalWithoutPADetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Based on the information provided in this risk assessment can
                  the child / young person travel alone without a passenger
                  assistant?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={from.parentStdRskAsmntTravelAloneWOPA === true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={from.parentStdRskAsmntTravelAloneWOPA === false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentStdRskAsmntTravelAloneWOPADetail}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Does the responsible adult agree to the child / young person
                  being transported in a vehicle without a Passenger Assistant?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentStdRespAdultStdTransportVehicleWOPA === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentStdRespAdultStdTransportVehicleWOPA === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentStdRespAdultStdTransportVehicleWOPADetail}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>
                  Is the responsible adult aware the vehicles that the young people are transported in do not have CCTV?
                </p>
              </div>
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      className="mx-3"
                      type="radio"
                      label="Yes"
                      checked={
                        from.parentAwareYongPeopleTransInVehDoNotCCTV === true
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      checked={
                        from.parentAwareYongPeopleTransInVehDoNotCCTV === false
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-5">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled
                    value={from.parentAwareYongPeopleTransInVehDoNotCCTVDetail}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        {from.riskAssessmentStatus === "Risk Assessment Completed" && (
          <div className="card border-success mb-3">
            <div className="card-header">
              <h5>Comments</h5>
            </div>
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-md-5">
                  <p>Add Comment</p>
                </div>

                <div className="col-md-5">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      as="textarea"
                      name="comment"
                      rows={6}
                      maxLength={3999}
                      onChange={handleChange}
                      placeholder="Comment"
                    />
                  </Form.Group>
                  {error && !comment && (
                    <span className="error">
                      Please add your comment here.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {from.riskAssessmentStatus === "Risk Assessment Completed" && (
          <div className="d-flex justify-content-end ml-auto">
            <button className="index-form-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskAssessment;
