import * as Yup from "yup";
import {
    EducationTypes,
    GroundsForApplication,
    MobilityEquipmentTypes,
    Seat,
} from "./Constants";

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const transportRequestSchema = Yup.object().shape({
    ContactTitle: Yup.string().required("Please specify contact title."),
    ContactFirstName: Yup.string().required("Please specify contact first name."),
    ContactLastName: Yup.string().required("Please specify contact last name."),
    ContactAddressline1: Yup.string().required(
        "Please specify contact house name/number."
    ),
    ContactAddressline2: Yup.string().required(
        "Please specify contact street name."
    ),
    ContactAddressline3: Yup.string().required(
        "Please specify contact locality."
    ),
    ContactAddressline4: Yup.string().required(
        "Please specify contact town/city."
    ),
    ContactAddressPostcode: Yup.string().required(
        "Please specify contact postcode."
    ),
    ContactEmail: Yup.string()
        .matches(emailRegExp, "Please specify a valid contact email address.")
        .required("Please specify a valid contact email address."),
    ContactPhone: Yup.string()
        .matches(phoneRegExp, "Please specify a valid contact phone number.")
        .required("Please specify a valid contact phone number."),
    StudentType: Yup.string().required("Please select the student type."),
    StudentId: Yup.string().when("StudentType", {
        is: "Existing",
        then: Yup.string().required(
            "Please select the existing child / young person."
        ),
    }),
    StudentFirstName: Yup.string().when("StudentType", {
        is: "New",
        then: Yup.string().required(
            "Please specify child / young person first name."
        ),
    }),
    StudentLastName: Yup.string().when("StudentType", {
        is: "New",
        then: Yup.string().required("Please specify child / young person surname."),
    }),
    StudentSex: Yup.string().when("StudentType", {
        is: "New",
        then: Yup.string().required("Please enter child / young person's sex."),
    }),
    StudentDateOfBirth: Yup.string().when("StudentType", {
        is: "New",
        then: Yup.string().required("Please enter child / young person's d.o.b."),
    }),
    ContactRelationship: Yup.string().required(
        "Please specify your relationship to the student."
    ),
    StudentDetailsInCare: Yup.string().required(
        "Please specify if the student is in or leaving care."
    ),
    StudentDetailsSocialWorker: Yup.string().when("StudentDetailsInCare", {
        is: "true",
        then: Yup.string().required("Please specify social worker full name."),
    }),
    EducationSchoolType: Yup.number().required("Please select education type."),
    StudentDetailsLivesAtDifferentAddress: Yup.string().when(
        "EducationSchoolType",
        {
            is: (EducationSchoolType) =>
                EducationSchoolType == EducationTypes.PRIMARY ||
                EducationSchoolType == EducationTypes.SECONDARY,
            then: Yup.string().required(
                "Please select if the child / young person lives at a different address."
            ),
        }
    ),
    StudentDetailsAddressLine1: Yup.string().when(
        "StudentDetailsLivesAtDifferentAddress",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify child / young person house name/number."
            ),
        }
    ),
    StudentDetailsAddressLine2: Yup.string().when(
        "StudentDetailsLivesAtDifferentAddress",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify child / young person street name."
            ),
        }
    ),
    StudentDetailsAddressLine3: Yup.string().when(
        "StudentDetailsLivesAtDifferentAddress",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify child / young person locality."
            ),
        }
    ),
    StudentDetailsAddressLine4: Yup.string().when(
        "StudentDetailsLivesAtDifferentAddress",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify child / young person town/city."
            ),
        }
    ),
    studentDetailsAddressPostcode: Yup.string().when(
        "StudentDetailsLivesAtDifferentAddress",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify child / young person postcode."
            ),
        }
    ),
    SchoolId: Yup.string().required("Please select school/college name."),
    EducationSchoolAdmittanceDate: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType == EducationTypes.PRIMARY ||
            EducationSchoolType == EducationTypes.SECONDARY,
        then: Yup.string().required("Please specify school admittance date."),
    }),
    EducationCourseTitle: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required("Please specify the course title."),
    }),
    EducationQualification: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required("Please specify the qualification."),
    }),
    GroundsForApplication: Yup.number().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType == -1 ||
            EducationSchoolType == EducationTypes.PRIMARY ||
            EducationSchoolType == EducationTypes.SECONDARY,
        then: Yup.number().required("Please select grounds for application."),
    }),
    EducationHoursPerWeek: Yup.number().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.number().required(
            "Please select the hours per week for the course."
        ),
    }),
    EducationFirstYearOfStudy: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required(
            "Please select if this is the first year of study."
        ),
    }),
    EducationNearestSchoolCollege: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required(
            "Please select if child / young person is at nearest school/college."
        ),
    }),
    EducationWhyNotNearestSchool: Yup.string().when(
        "EducationNearestSchoolCollege",
        {
            is: "false",
            then: Yup.string().required("Required"),
        }
    ),
    TransportWhyNotFamilyOrFriends: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required(
            "Please state why transport can't be provided by family or friends."
        ),
    }),
    StudentDetailsHasDisabilityLivingAllowance: Yup.string().when(
        "EducationSchoolType",
        {
            is: (EducationSchoolType) =>
                EducationSchoolType !== -1 &&
                EducationSchoolType !== EducationTypes.PRIMARY &&
                EducationSchoolType !== EducationTypes.SECONDARY,
            then: Yup.string().required(
                "Please select if child / young person has disability living allowance (DLA)."
            ),
        }
    ),
    EducationHasAppliedForBursary: Yup.string().when("EducationSchoolType", {
        is: (EducationSchoolType) =>
            EducationSchoolType !== -1 &&
            EducationSchoolType !== EducationTypes.PRIMARY &&
            EducationSchoolType !== EducationTypes.SECONDARY,
        then: Yup.string().required(
            "Please select if child / young person has applied for bursary."
        ),
    }),
    FreeSchoolMealsEntitlement: Yup.string().when("GroundsForApplication", {
        is: GroundsForApplication.LOW_INCOME,
        then: Yup.string().required("Required"),
    }),
    MaximumTaxCredits: Yup.string().when("GroundsForApplication", {
        is: GroundsForApplication.LOW_INCOME,
        then: Yup.string().required("Required"),
    }),
    EducationHasEHCPPlan: Yup.string().required(
        "Please select if the child / young person has an Education, Health and Care plan (EHCP)."
    ),
    EducationEHCPFinalisedDate: Yup.string().when("educationHasEHCPPlan", {
        is: "true",
        then: Yup.string().required("Required"),
    }),
    TransportHasOwnVehicle: Yup.string().required(
        "Please select if you own your own vehicle."
    ),
    TransportStartDate: Yup.string().required(
        "Please specify when you would like the travel assistance to start."
    ),
    InYearOrNextAcademicYear: Yup.string().required(
        "Please specify when are you applying for travel assistance for the new school year or within the school year?"
    ),
    EmergencyContact1Name: Yup.string().required(
        "Please specify emergency contact 1 name."
    ),
    EmergencyContact1Relationship: Yup.string().required(
        "Please specify emergency contact 1 relationship."
    ),
    EmergencyContact1Phone: Yup.string()
        .matches(phoneRegExp, "Please specify a valid emergency contact 1 phone.")
        .required("Please specify a valid emergency contact 1 phone."),
    EmergencyContact1Email: Yup.string()
        .matches(
            emailRegExp,
            "Please specify a valid emergency contact 1 email address."
        )
        .required("Please specify a valid emergency contact 1 email address."),
    EmergencyContact2Name: Yup.string().required(
        "Please specify emergency contact 2 name."
    ),
    EmergencyContact2Relationship: Yup.string().required(
        "Please specify emergency contact 2 relationship."
    ),
    EmergencyContact2Phone: Yup.string()
        .matches(phoneRegExp, "Please specify a valid emergency contact 2 phone.")
        .required("Please specify a valid emergency contact 2 phone."),
    EmergencyContact2Email: Yup.string()
        .matches(
            emailRegExp,
            "Please specify a valid emergency contact 2 email address."
        )
        .required("Please specify a valid emergency contact 2 email address."),
    SendOrMedicalHasSendOrMedicalNeeds: Yup.string().required(
        "Please specify if the student has special educational or medical needs."
    ),
    SendOrMedicalDetails: Yup.string().when(
        "SendOrMedicalHasSendOrMedicalNeeds",
        {
            is: "true",
            then: Yup.string().required(
                "Please specify special educational or medical needs."
            ),
        }
    ),
    MobilityHasIssues: Yup.string().when("sendOrMedicalHasSendOrMedicalNeeds", {
        is: "true",
        then: Yup.string().required("Required"),
    }),
    MobilityDetails: Yup.string().when("MobilityHasIssues", {
        is: (SendOrMedicalHasSendOrMedicalNeeds, MobilityHasIssues) =>
            SendOrMedicalHasSendOrMedicalNeeds === "true" &&
            MobilityHasIssues === "true",
        then: Yup.string().required("Please specify mobility issues."),
    }),
    MobilityEquipment: Yup.number().when(
        ["SendOrMedicalHasSendOrMedicalNeeds", "MobilityHasIssues"],
        {
            is: (SendOrMedicalHasSendOrMedicalNeeds, MobilityHasIssues) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                MobilityHasIssues === "true",
            then: Yup.number().required(
                "Please select mobility equipment (wheelchair/buggy etc)."
            ),
        }
    ),
    MobilityEquipmentDimensions: Yup.string().when(
        [
            "SendOrMedicalHasSendOrMedicalNeeds",
            "MobilityHasIssues",
            "MobilityEquipment",
        ],
        {
            is: (
                SendOrMedicalHasSendOrMedicalNeeds,
                MobilityHasIssues,
                MobilityEquipment
            ) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                MobilityHasIssues == "true" &&
                MobilityEquipment !== -1 &&
                MobilityEquipment !== MobilityEquipmentTypes.NOT_APPLICABLE &&
                MobilityEquipment !== MobilityEquipmentTypes.WALKING_FRAME,
            then: Yup.string().required("Required"),
        }
    ),
    MobilityCanTransferToSeatWhilstTravelling: Yup.string().when(
        [
            "SendOrMedicalHasSendOrMedicalNeeds",
            "MobilityHasIssues",
            "MobilityEquipment",
        ],
        {
            is: (
                SendOrMedicalHasSendOrMedicalNeeds,
                MobilityHasIssues,
                MobilityEquipment
            ) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                MobilityHasIssues == "true" &&
                MobilityEquipment !== -1 &&
                MobilityEquipment !== MobilityEquipmentTypes.NOT_APPLICABLE &&
                MobilityEquipment !== MobilityEquipmentTypes.WALKING_FRAME,
            then: Yup.string().required("Required"),
        }
    ),
    SeizuresHasSeizures: Yup.string().when("SendOrMedicalHasSendOrMedicalNeeds", {
        is: "true",
        then: Yup.string().required(
            "Please specify if the child / young person has seizures."
        ),
    }),
    SeizuresType: Yup.string().when(
        ["SendOrMedicalHasSendOrMedicalNeeds", "SeizuresHasSeizures"],
        {
            is: (SendOrMedicalHasSendOrMedicalNeeds, SeizuresHasSeizures) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                SeizuresHasSeizures === "true",
            then: Yup.string().required("Please specify seizure type."),
        }
    ),
    SeizuresSigns: Yup.string().when(
        ["SendOrMedicalHasSendOrMedicalNeeds", "SeizuresHasSeizures"],
        {
            is: (SendOrMedicalHasSendOrMedicalNeeds, SeizuresHasSeizures) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                SeizuresHasSeizures === "true",
            then: Yup.string().required("Required"),
        }
    ),
    SeizuresFrequency: Yup.string().when(
        ["SendOrMedicalHasSendOrMedicalNeeds", "SeizuresHasSeizures"],
        {
            is: (SendOrMedicalHasSendOrMedicalNeeds, SeizuresHasSeizures) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                SeizuresHasSeizures === "true",
            then: Yup.string().required("Please specify seizure occurrence."),
        }
    ),
    Transport_HasTransportMedication: Yup.string().when(
        "SendOrMedicalHasSendOrMedicalNeeds",
        {
            is: "true",
            then: Yup.string().required(
                "Please select if medication needs to be transported with student."
            ),
        }
    ),
    Transport_MedicationDetails: Yup.string().when(
        ["SendOrMedicalHasSendOrMedicalNeeds", "Transport_HasTransportMedication"],
        {
            is: (
                SendOrMedicalHasSendOrMedicalNeeds,
                Transport_HasTransportMedication
            ) =>
                SendOrMedicalHasSendOrMedicalNeeds === "true" &&
                Transport_HasTransportMedication === "true",
            then: Yup.string().required("Please specify medication details."),
        }
    ),
    // TransportSeatType: Yup.number().required(
    //   "Please select if a specific seat is required."
    // ),
    // TransportHarnessRequired: Yup.string().when("TransportSeatType", {
    //   is: (TransportSeatType) =>
    //     TransportSeatType !== -1 && TransportSeatType !== Seat.NOT_APPLICABLE,
    //   then: Yup.string().required(
    //     "Please select if a special harness or seat is required."
    //   ),
    // }),
    // TransportSeatDetails: Yup.string().when("TransportSeatType", {
    //   is: (TransportSeatType) =>
    //     TransportSeatType !== -1 && TransportSeatType !== Seat.NOT_APPLICABLE,
    //   then: Yup.string().required("Please specify seat details."),
    // }),
    // TransportRemoveSeatBelt: Yup.string().required(
    //   "Please specify if the child / young person will try to take off their seatbelt."
    // ),
    // TransportShareTransport: Yup.string().required(
    //   "Please specify if the child / young person is able to share transport."
    // ),
    // TransportNoSharedTransportDetails: Yup.string().when(
    //   "TransportShareTransport",
    //   {
    //     is: "false",
    //     then: Yup.string().required(
    //       "Please specify why child / young person can't share transport."
    //     ),
    //   }
    // ),
    TransportTravelTraining: Yup.string().required(
        "Please select if the child / young person has had previous travel training."
    ),
    TransportRoadSafety: Yup.string().required(
        "Please select does the child / young person have a basic understanding of road safety?."
    ),
});

export const formatTransportRequestData = (transportRequestDetails) => {
    let EmergencyContacts = [
        {
            fullname: transportRequestDetails.EmergencyContact1Name,
            email: transportRequestDetails.EmergencyContact1Email,
            phone: transportRequestDetails.EmergencyContact1Phone,
            relationship: transportRequestDetails.EmergencyContact1Relationship,
        },
        {
            fullname: transportRequestDetails.EmergencyContact2Name,
            email: transportRequestDetails.EmergencyContact2Email,
            phone: transportRequestDetails.EmergencyContact2Phone,
            relationship: transportRequestDetails.EmergencyContact2Relationship,
        },
    ];

    transportRequestDetails.EmergencyContacts = EmergencyContacts;

    if (transportRequestDetails.StudentType === "New") {
        let Student = {
            FirstName: transportRequestDetails.StudentFirstName,
            LastName: transportRequestDetails.StudentLastName,
            DateOfBirth: transportRequestDetails.StudentDateOfBirth,
            Sex: transportRequestDetails.StudentSex,
        };
        transportRequestDetails.Student = Student;

        delete transportRequestDetails["StudentId"];
    }

    if (transportRequestDetails.StudentType === "Existing") {
        delete transportRequestDetails["Student"];
    }

    if (transportRequestDetails.StudentDetailsInCare === "false") {
        delete transportRequestDetails["StudentDetailsSocialWorker"];
    }

    if (
        transportRequestDetails.EducationSchoolType === EducationTypes.PRIMARY ||
        transportRequestDetails.EducationSchoolType === EducationTypes.SECONDARY
    ) {
        delete transportRequestDetails["EducationCourseTitle"];
        delete transportRequestDetails["EducationQualification"];

        delete transportRequestDetails["EducationHoursPerWeek"];
        delete transportRequestDetails["EducationFirstYearOfStudy"];

        if (transportRequestDetails.EducationNearestSchoolCollege === "true") {
            delete transportRequestDetails["EducationWhyNotNearestSchool"];
        }

        delete transportRequestDetails["EducationNearestSchoolCollege"];
        delete transportRequestDetails["TransportWhyNotFamilyOrFriends"];
        delete transportRequestDetails[
            "StudentDetailsHasDisabilityLivingAllowance"
        ];
        delete transportRequestDetails["EducationHasAppliedForBursary"];
    }

    if (
        transportRequestDetails.EducationSchoolType !== EducationTypes.PRIMARY &&
        transportRequestDetails.EducationSchoolType !== EducationTypes.SECONDARY
    ) {
        if (
            transportRequestDetails.StudentDetailsLivesAtDifferentAddress === "false"
        ) {
            delete transportRequestDetails["StudentDetailsAddressLine1"];
            delete transportRequestDetails["StudentDetailsAddressLine2"];
            delete transportRequestDetails["StudentDetailsAddressLine3"];
            delete transportRequestDetails["StudentDetailsAddressLine4"];
        }
        delete transportRequestDetails["EducationSchoolAdmittanceDate"];
        delete transportRequestDetails["StudentDetailsLivesAtDifferentAddress"];

        if (
            transportRequestDetails.GroundsForApplication !==
            GroundsForApplication.LOW_INCOME
        ) {
            delete transportRequestDetails["FreeSchoolMealsEntitlement"];
            delete transportRequestDetails["MaximumTaxCredits"];
        }
        delete transportRequestDetails["GroundsForApplication"];
    }

    if (transportRequestDetails.EducationHasEHCPPlan === "false") {
        delete transportRequestDetails["EducationEHCPFinalisedDate"];
    }

    if (transportRequestDetails.SendOrMedicalHasSendOrMedicalNeeds === "false") {
        delete transportRequestDetails["SendOrMedicalDetails"];

        if (transportRequestDetails.MobilityHasIssues === "false") {
            delete transportRequestDetails["MobilityDetails"];
            if (
                transportRequestDetails.MobilityEquipment ===
                MobilityEquipmentTypes.NOT_APPLICABLE &&
                transportRequestDetails.MobilityEquipment ===
                MobilityEquipmentTypes.WALKING_FRAME
            ) {
                delete transportRequestDetails["MobilityEquipmentDimensions"];
                delete transportRequestDetails[
                    "MobilityCanTransferToSeatWhilstTravelling"
                ];
            }
            delete transportRequestDetails["MobilityEquipment"];
        }

        delete transportRequestDetails["MobilityHasIssues"];

        if (transportRequestDetails.SeizuresHasSeizures === "false") {
            delete transportRequestDetails["SeizuresType"];
            delete transportRequestDetails["SeizuresSigns"];
            delete transportRequestDetails["SeizuresFrequency"];
        }

        if (transportRequestDetails.Transport_HasTransportMedication === "false") {
            delete transportRequestDetails["Transport_MedicationDetails"];
        }
        delete transportRequestDetails["Transport_HasTransportMedication"];
    }

    // if (
    //   transportRequestDetails.TransportSeatType === -1 &&
    //   transportRequestDetails.TransportSeatType === Seat.NOT_APPLICABLE
    // ) {
    //   delete transportRequestDetails["TransportHarnessRequired"];
    //   delete transportRequestDetails["TransportSeatDetails"];
    // }

    // if (transportRequestDetails.TransportShareTransport === "true") {
    //   delete transportRequestDetails["TransportNoSharedTransportDetails"];
    // }

    delete transportRequestDetails["EmergencyContact1Name"];
    delete transportRequestDetails["EmergencyContact1Email"];
    delete transportRequestDetails["EmergencyContact1Phone"];
    delete transportRequestDetails["EmergencyContact1Relationship"];
    delete transportRequestDetails["EmergencyContact2Name"];
    delete transportRequestDetails["EmergencyContact2Email"];
    delete transportRequestDetails["EmergencyContact2Phone"];
    delete transportRequestDetails["EmergencyContact2Relationship"];
    delete transportRequestDetails["StudentFirstName"];
    delete transportRequestDetails["StudentLastName"];
    delete transportRequestDetails["StudentDateOfBirth"];
    delete transportRequestDetails["StudentSex"];

    // transportRequestDetails.StudentId == "" && delete transportRequestDetails["StudentId"];

    let keys = Object.keys(transportRequestDetails);
    keys.forEach((key) => {
        if (
            transportRequestDetails[key] === "true" ||
            transportRequestDetails[key] === "false"
        ) {
            transportRequestDetails[key] = JSON.parse(transportRequestDetails[key]);
        }
    });

    return transportRequestDetails;
};

export const profileSettingsSchema = Yup.object().shape({
    title: Yup.string().required("Please enter the title.").nullable(),
    firstName: Yup.string().required("Please enter the first name.").nullable(),
    lastName: Yup.string().required("Please enter the last name.").nullable(),
    phone: Yup.string().required("Please enter the phone number.").nullable(),
    dateofBirth: Yup.string()
        .required("Please enter the date of birth.")
        .nullable(),
    jobTitle: Yup.string().required("Please enter the job title.").nullable(),
    addressline1: Yup.string()
        .required("Please enter the house name/number.")
        .nullable(),
    addressline2: Yup.string()
        .required("Please enter the street name.")
        .nullable(),
    addressline3: Yup.string().required("Please enter the area.").nullable(),
    city: Yup.string().required("Please enter the town/city.").nullable(),
    postalCode: Yup.string().required("Please enter the post code.").nullable(),
});

export const bankingDetail = Yup.object().shape({
    accountNumber: Yup.string()
        .required("Please enter the Account Number.")
        .nullable(),
    branchName: Yup.string().required("Please enter the Branch Name.").nullable(),
    buildingSocietyRoleNumber: Yup.string()
        .required("Please enter the Building Society Role Number.")
        .nullable(),
    payeeAddress: Yup.string()
        .required("Please enter the Payee Address.")
        .nullable(),
    payeeName: Yup.string().required("Please enter the Payee Name.").nullable(),
    sortCode: Yup.string().required("Please enter the Sort Code.").nullable(),
});

export const checkboxesListSchema = Yup.object().shape({
    ReadTransportPolicy: Yup.bool().oneOf([true], "").required(""),
    AccurateDetails: Yup.bool().oneOf([true], "").required(""),
    UploadedEvidence: Yup.bool().oneOf([true], "").required(""),
    ReadTransportPost16Policy: Yup.bool().oneOf([true], "").required(""),
});
