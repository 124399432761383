export const b2cPolicies = {
    names: {
        signUpSignOn: 'B2C_1_SignUpSignOn',
        passwordReset: 'B2C_1_Password_Reset'
    },
    authorities: {
        signUpSignOn: {
            authority: 'https://travelassistanceb2c.b2clogin.com/travelassistanceb2c.onmicrosoft.com/B2C_1_SignUpSignOn',
        },
        passwordReset: {
            authority: 'https://travelassistanceb2c.b2clogin.com/travelassistanceb2c.onmicrosoft.com/B2C_1_Password_Reset',
            scopes: ["https://travelassistanceb2c.onmicrosoft.com/65950a30-17ca-4324-bf85-32984b685d12/access_as_user"],
        }
    },
    authorityDomain: 'travelassistanceb2c.b2clogin.com',
};
export const msalConfig = {
    auth: {
        clientId: "aab1687a-23cb-4477-985d-fa5ce674d32e",
        authority:  b2cPolicies.authorities.signUpSignOn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "https://travelassistance.telford.gov.uk/",
        // redirectUri: "https://travelassistance.azurewebsites.net/",
        navigagteToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};