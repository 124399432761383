import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Policies = () => {
  const [show, setShow] = useState(true);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheck = () => {
    setCheckboxValue(!checkboxValue);
  };
  return (
    <>
      <Modal size="lg" centered backdrop="static" show={show} onHide={handleClose} keyboard={false}>
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="formwrapper content-wrap policies-box">
            <p>
              Before completing this application form please ensure you have
              read the Travel Assistance Policy relevant to your application as
              well as the guidance notes on applying for travel assistance:
            </p>
            <ul>
              <li className="link">
                <a
                  href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/2"
                  title="Download the home to school transport policy from the Council website"
                  target="_blank"
                >
                  School Travel Assistance Policy (opens new window)
                </a>
              </li>
              <li className="link">
                <a
                  href="https://www.telford.gov.uk/downloads/file/18209/post_16_travel_assistance_policy_2021_-_2022"
                  title="Download the post 16 transport policy 2021 - 2022 from the Council website"
                  target="_blank"
                >
                  Post 16 Travel Assistance Policy 2024 - 2025 (opens new
                  window)
                </a>
              </li>
              <li className="link">
                <a
                  href="https://www.telford.gov.uk/downloads/file/14857/the_travel_assistance_spare_seat_offer_may_2022"
                  title="Download the post 16 transport policy from the Council website"
                  target="_blank"
                >
                  The Travel Assistance Spare Seat Offer May 2022 (opens new
                  window)
                </a>
              </li>
            </ul>
            <div className-mb-3="">
              <p className="text-danger">
                <strong>
                  Please read the following prior to making your application:
                </strong>
              </p>
              <ul>
                <li className="link">
                  It can take up to 20 working days to process your application
                  as each case is considered on its own merits so, you must
                  organise you own travel assistance whilst an application is
                  being considered.
                </li>
                <li className="link">
                  For Post 16 applications the Young person, or carer on behalf
                  of the child / young person, should apply for a bursary from
                  the chosen school/college before making an application for
                  travel assistance.
                </li>
                <li className="link">
                  Please be aware that in line with the Post 16 travel assistance
                  policy you will be requested to pay a contribution of £600 or £400 per annum
                  in the following circumstances: A funded travel pass to occupy a seat on a taxi
                  or minibus or a taxi uplift Personal Transport Budget (PTB) are only provided in
                  exceptional circumstances. For these modes of travel, a contribution of £600
                  per academic year from students or their parents is required towards the cost of
                  this travel assistance. The charge will be £400 per academic year, if a financially
                  dependent student is from a low income household.
                </li>
              </ul>
            </div>
            <hr />
            <h4>Am I entitled to free school travel assistance? </h4>
            <p>
              Generally, pupils in classes reception to year 11 are entitled to
              free school travel assistance if they attend the nearest or
              catchment area school to their home address and meet the
              eligibility criteria. For pupils aged 7 or below, you must live
              over two miles from the school. Or pupils aged 8 and above, you
              must live over three miles from the school. This is measured by
              the nearest available route (shortest walking distance).
            </p>
            <p>
              There are exceptional circumstances -{" "}
              <a
                href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/home_to_school_transport/2"
                target="_blank"
              >
                View the Travel Assistance Policies
              </a>
              .
            </p>
            <p>
              The policy for travel assistance for post 16 education and
              training is different from that for students of compulsory school
              age.{" "}
              <a
                href="https://www.telford.gov.uk/downloads/file/18209/post_16_travel_assistance_policy_2021_-_2022"
                target="_blank"
              >
                Download the travel assistance policy.
              </a>
            </p>
            <hr />
            <h4>
              Travel assistance for children with special educational needs,
              disabilities and mobility problems
            </h4>
            <p>The following eligibility criteria is applied:</p>
            <ul>
              <li className="link">
                where the child cannot reasonably be expected to walk to school
                (accompanied by a parent/carer as necessary and considering the
                age of the child, as well as whether one would normally expect a
                child of that age to be accompanied) because of mobility
                problems or because of associated health and safety issues
                related to their Special Educational Needs (SEN) or Disability,
                we will review and assess each child on an individual basis.
                Usual travel requirements (E.g. the statutory walking distances)
                may not be considered when assessing the travel needs of
                children eligible due to SEN and/ or disability{" "}
              </li>
              <li className="link">
                the pupil must be attending the nearest qualifying school or a
                setting named on his or her Education, Health and Care Plan
                (EHCP), following formal consultation by the SEN team, rather
                than a setting named due to parental preference{" "}
              </li>
              <li className="link">
                where a pre-school child with an EHCP has been placed at a
                nursery, special school or other specialist provision by the
                Council, travel assistance may be provided in the same way as
                for children of statutory school age but a charge will be made.
                The charge will follow the same charging approach set out in the
                Post 16 Policy’s non statutory charge{" "}
              </li>
              <li className="link">
                when applying for travel assistance, parents and carers of
                children with SEN and mobility issues, are asked to complete a
                comprehensive online questionnaire which collects information to
                help officers make an informed decision about the application.
                This questionnaire asks for detailed information about the
                child’s health, abilities with regards travel and interventions
                should the child not be happy at any stage during their travel.
                Please complete this form with as much detail as possible.
              </li>
            </ul>
            <p>
              The policy for travel assistance for post 16 education and
              training is different from that for students of compulsory school
              age.{" "}
              <a
                href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/home_to_school_transport/2"
                target="_blank"
              >
                View the Travel Assistance Policies.
              </a>
            </p>
            <hr />
            <h4>
              Privacy Notice under the General Data Protection Regulations 2018{" "}
            </h4>
            <p>
              Telford &amp; Wrekin Council are collecting personal data to
              enable the best possible advice, care or support to be provided to
              you, your child, a family member or someone you care for whilst
              travelling with Telford and Wrekin Council in line with Article
              9(2)(c) &amp; (h), Article 6(1)(b) and Article 6(1)(e) of the General Data Protection Regulations 2018
              or equivalent United Kingdom legislation.
            </p>
            <p>
              Telford &amp; Wrekin Council will only share your personal data
              collected with the travel assistance provider that is chosen to
              provide your travel assistance, we will not share any of your
              personal data with any other external organisations unless
              required to do so by law. However, there may be occasions where we
              request further information from key third party organisations
              such as Health or Provider organisations. For further details on
              the council’s privacy arrangements please view the privacy page on
              the council’s website page{" "}
              <a href="https://www.telford.gov.uk/terms" target="_blank">
                https://www.telford.gov.uk/terms
              </a>
              .
            </p>
          </div>
          <div class="form-check mt-3 p-0 d-flex align-items-center">
            <input
              class="form-check-input"
              type="checkbox"
              name="AccurateDetails"
              // checked={checkboxValue}
              onClick={handleCheck}
            />
            <label class="form-check-label" for="Data_AccurateDetails">
              I have read and agree to the terms and conditions.
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={checkboxValue === false}
            className="modal-btn"
            onClick={handleClose}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Policies;
