import axios from 'axios';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../utils/msal/config';
const msalInstance = new PublicClientApplication(msalConfig);

const baseURL = "https://travelassistancedotnet.azurewebsites.net/api/";

let customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
let AzureADB2C = JSON.parse(localStorage.getItem("AzureADB2C")) || {};

axios.interceptors.request.use(async config => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const request = {
        scopes: ["https://travelassistanceb2c.onmicrosoft.com/65950a30-17ca-4324-bf85-32984b685d12/access_as_user"],
        account: activeAccount || accounts[0]
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    config.headers.Authorization = 'Bearer' + ' ' + authResult.accessToken;

    return config;
});

export const getToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const request = {
        scopes: ["https://travelassistanceb2c.onmicrosoft.com/65950a30-17ca-4324-bf85-32984b685d12/access_as_user"],
        account: activeAccount || accounts[0]
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + authResult.accessToken;
    return authResult.accessToken;
}
export const getSchoolList = async (schoolType) => {
    const list = await axios.get(baseURL + "School/" + schoolType);

    return list.data;
};

export const getExistingStudent = async () => {
    const list = await axios.get(baseURL + `Student/GetMultiple/emptyparameters`);

    return list.data;
};

export const getGroundsOfApplication = async () => {
    const list = await axios.post(baseURL + "OptionSet/GetOptions/", {
        optionSetLogicalName: "ss_mobilityequipment",
        entityLogicalName: "ss_transportrequest",
    });

    return list.data;
};

export const createTransportRequest = async (TransportRequestDetails) => {
    // TransportRequestDetails.CustomerId = "emptycustomerid";
    const transportRequest = await axios.post(
        baseURL + "TransportRequest",
        TransportRequestDetails
    );

    return transportRequest;
};

export const getTransportRequests = async () => {
    const transportRequests = await axios.get(
        baseURL + "TransportRequest/emptyparameters"
    );

    return transportRequests.data;
};

export const getEmergencyContactDetails = async (studentDetails) => {
    const ec = {
        entityLogicalName: "ss_student",
        entityId: studentDetails,
        relationship: "ss_student_ss_emergencycontact",
    };
    const list = await axios.post(
        baseURL + "EmergencyContact/GetEmergencyContacts",
        ec
    );

    return list.data;
};

export const getRiskAssessments = async () => {
    const riskAssessments = await axios.get(
        baseURL + `RiskAssessment/emptyparameters`
    );

    return riskAssessments.data;
};

export const updateRiskAssessment = async (data) => {
    const riskAssessment = await axios.put(baseURL + "RiskAssessment", data);

    return riskAssessment.data;
};

export const GetStudentDetail = async () => {
    const list = await axios.get(baseURL + "Student");

    return list.data;
};

export const getCustomerDetails = async (data) => {
    const customerDetails = await axios.post(baseURL + "Customer", data);

    return customerDetails.data;
};

export const updateCustomerDetails = async (data) => {
    const customerDetails = await axios.put(baseURL + "Customer", data);

    return customerDetails.data;
};

export const getTransportLetters = async () => {
    const transportLetters = await axios.get(
        baseURL + "Passenger/emptyparameters"
    );

    return transportLetters.data;
};

export const updateTransportLetter = async (data) => {
    const transportLetter = await axios.put(baseURL + "Passenger", data);

    return transportLetter.data;
};

export const getTransportAgreements = async () => {
    const transportAgreements = await axios.get(
        baseURL + "Agreement/emptyparameters"
    );

    return transportAgreements.data;
};

export const updateTransportAgreement = async (data) => {
    const transportAgreement = await axios.put(baseURL + "Agreement", data);

    return transportAgreement.data;
};
