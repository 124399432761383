import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <p>&copy; Telford &amp; Wrekin Council ICT - 2023</p>
          <div className="footer-li">
            <ul>
              <li>
                <a href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/4" target="_blank">FAQs</a>
              </li>
              <li>
                <a href="https://www.telford.gov.uk/terms" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/5" target="_blank">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
