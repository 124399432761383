import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { getTransportLetters } from "../../services/Index";

const TransportLetterList = () => {
  const [loading, setLoading] = useState(true);
  const [transportLetters, setTransportLetters] = useState([]);

  useEffect(() => {
    getTransportLetters().then((res) => {
      setTransportLetters(res);
      setLoading(false);
    });
  }, []);

  const getFormattedDate = (dateofBirth) => {
    const dateString = dateofBirth;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // add leading zero if needed
    const day = ("0" + date.getDate()).slice(-2); // add leading zero if needed
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  return (
    <div className="riskassessment-footer">
      <div className="container">
        <div className="list-table">
          <h1 className="mt-3"> List of Transport Letters</h1>
          {loading && (
            <div className="center">
              <RotatingLines
                strokeColor="#4e3782"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          {transportLetters.length > 0 && (
            <div className="">
              <div className="mb-3 mt-4"></div>
              <div className="table-width-box">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Transport Request</th>
                      <th>Child / Young Person Name</th>
                      <th>Passenger Number</th>
                      <th>Transport Contract Type</th>
                      <th>Transport Start and End Date</th>
                      <th>Passenger Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transportLetters &&
                      transportLetters.map((item) => (
                        <tr>
                          <td>
                            {item.transportRequest &&
                              item.transportRequest.transportRequestNumber}
                          </td>
                          <td>{item.student.firstName + " " + item.student.lastName}</td>
                          <td>{item.passengerNumber}</td>
                          <td>{item.contract && item.contract.contractType}</td>
                          <td>
                            {`${item.startDate
                                ? getFormattedDate(new Date(item.startDate).toLocaleDateString("en-US"))
                                : "N/A"
                              } — ${item?.endDate
                                ? getFormattedDate(new Date(item.endDate).toLocaleDateString("en-US"))
                                : "N/A"
                              }`}
                          </td>
                          <td>{item && item.passengerStatusReason}</td>
                          <td className="tr-table-btn">
                            <button className="disabled-btn">
                              {" "}
                              <Link
                                to={`/TransportLetterDetail`}
                                state={{ from: item }}
                              >
                                Open Transport Letter
                              </Link>
                              {/* <Link to={`/RiskAssessment`}>View</Link> */}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {transportLetters.length === 0 && loading === false && (
          <div className="alert alert-info mt-3">
            You do not yet have any transport letters to review.
          </div>
        )}
      </div>
    </div>
  );
};

export default TransportLetterList;
