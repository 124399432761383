import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { getRiskAssessments } from "../../services/Index";

const RiskAssessmentList = () => {
  const [loading, setLoading] = useState(true);
  const [riskAssessments, setRiskAssessments] = useState([]);
  // const history = useHistory();

  useEffect(() => {
    getRiskAssessments().then((res) => {
      setRiskAssessments(res);
      setLoading(false);
    });
  }, []);

  return (
    <div className="riskassessment-footer">
      <div className="container">
        <div className="list-table">
          <h1 className="mt-3"> List of Risk Assessments</h1>
          {loading && (
            <div className="center">
              <RotatingLines
                strokeColor="#4e3782"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          {riskAssessments.length > 0 && (
            <div className="">
              <div className="mb-3 mt-4"></div>
              <div className="table-width-box">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Child / Young Person Name</th>
                      <th>Date of Birth</th>
                      <th>Assessment Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {riskAssessments &&
                      riskAssessments.map((curr) => (
                        <tr>
                          <td>{curr && curr.riskAssessmentNumber}</td>
                          <td>
                            {curr.tR_Customer_Student &&
                              curr.tR_Customer_Student.studentFullName}
                          </td>
                          <td>
                            {curr.tR_Customer_Student &&
                              new Date(
                                curr.tR_Customer_Student.studentDateOfBirth
                              ).toLocaleDateString("en-GB")}
                          </td>
                          <td>{curr && curr.type}</td>
                          <td className="tr-table-btn">
                            <button>
                              <Link
                                to={`/RiskAssessment`}
                                state={{ from: curr }}
                              >
                                {curr.riskAssessmentStatus ===
                                  "Risk Assessment Completed"
                                  ? "Click here to Approve Risk Assessment"
                                  : "Click here to View Risk Assessment"}
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {riskAssessments.length === 0 && loading === false && (
          <div className="alert alert-info mt-3">
            You do not yet have any risk assessments to review.
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskAssessmentList;
