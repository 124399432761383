import React from "react";
import { Carousel } from "react-bootstrap";
import banner from "../images/banner-3.jpg";
import banner2 from "../images/banner-4.jpg";
import banner3 from "../images/banner-5.jpg"

const Index = () => {
  return (
    <div>
      {/* <div className="container">
        <div class="formhead">
          <i class="fa fa-clipboard">&nbsp;</i>
          <span>
            Welcome to the Telford &amp; Wrekin Travel Assistance website
          </span>
        </div>
      </div> */}
      <section className="banner-sec">
        <Carousel>
          <Carousel.Item>
            <img className="w-100" src={banner} alt="banner-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="w-100" src={banner2} alt="banner-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="w-100" src={banner3} alt="banner-img" />
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="signup-sec">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2>School & College Travel Assistance in Telford & Wrekin</h2>
              <h2>
                Keep track of your child’s travel assistance application and travel arrangements with a travel assistance account </h2>
              <p>
                By creating an account you’ll also receive application updates, reminders and have access to tools to help you manage your child’s ongoing travel assistance.
              </p>
            </div>
            {/* <div>
              <button className="signup-btn">Register</button>
            </div> */}
          </div>
        </div>
      </section>
      <section className="section-three">
        <div className="container">
          <h2>Introduction</h2>
          <p>
            Some children and young people are eligible for school travel assistance.
          </p>
          <p>
            Where children are eligible for support, parents/carers can now make an online application. Please view <a target="_blank"
              href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/4">view frequently asked questions</a> and check to see if your child is eligible using the following policy documents before you make an application.
          </p>
          <ul>
            <li className="link">
              <a
                target="_blank"
                href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/2"
              >
                download the School Travel Assistance Policy for school travel assistance
              </a>
            </li>
            <li className="link">
              <a
                target="_blank"
                href="https://www.telford.gov.uk/downloads/file/18209/post_16_travel_assistance_policy_2022_-_2023"
              >
                download the Post 16 Travel Assistance Policy for school travel assistance for 2024 - 2025
              </a>
            </li>
            <li className="link">
              <a
                target="_blank"
                href="https://www.telford.gov.uk/downloads/file/14857/the_travel_assistance_spare_seat_offer_may_2022"
              >
                download the The Travel Assistance Spare Seat Offer for school travel assistance.
              </a>
            </li>
          </ul>
          <hr />
          <h2>Important information</h2>
          <p>
            Please be aware that as all decisions on eligibility for travel assistance forapplications are looked at on individual basis this could take up to 20 working days from submission to process.
            For those that are eligible the travel assistance team will then be in touch to determine the most appropriate form of travel assistance. Please see <a target="_blank"
              href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/school_travel_assistance/4">FAQ's</a> for further information.
          </p>
          <p>
            Please note, if you are applying for your child to receive travel assistance for September 2024 all applications must be received by <b>30 June 2024</b>. If any applications are received after this date your child will not necessarily get assistance in time for the start of the new school term.
          </p>
          <hr />
          <h2>How to apply for travel assistance</h2>
          <p>All travel assistance applications are managed through a parent/carer portal account. If you do not already have a parent/carer portal account then please create one by clicking the <b>Sign in / Register</b> link at the top right hand side of this page. </p>
          <h3>Who needs to apply?</h3>
          <ul>
            <li>
              If your child is statutory school age but does not currently receive travel assistance and is eligible then you need to apply.
            </li>
            <li>
              If your child is statutory school age, already receives travel assistance and is changing school in September then you will need to re-apply.
            </li>
            <li>
              If your child is statutory school age, already receives travel assistance and is not changing school in September then this will carry on next year and there is no need to re-apply.
            </li>
            <li>
              If your young person is leaving secondary school and is eligible for travel assistance to help them attend their post 16 setting then you will need to apply.
            </li>
            <li>
              If your young person is already in post 16 provision and receives travel assistance then they will need to re-apply.  Travel assistance is only granted for one year for post 16 students.
            </li>
            <li>
              You must also re-apply at any point in the year when you move house.
            </li>
          </ul>
          <p>It’s quick and easy to do and gives you access to manage your travel assistance applications and track progress. If your child/young person is already in receipt of travel assistance the portal will help you manage your account and anything related to your child/young person’s travel assistance in the future.
            If you need further support to create a parent/carer portal account please contact <b>01952 384545. (Monday – Friday 9am – 5pm)</b>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Index;
