export const ContactTitles = {
    MR: 126560000,
    MISS: 126560001,
    MRS: 126560002,
    MS: 126560003,
    CLLR: 126560004,
    REV: 126560005,
    DR: 126560006,
    LORD: 126560007,
    LADY: 126560008,
    SIRGENERAL: 126560009,
    CAPTAIN: 126560010,
    SERGENT: 126560011,
}
export const EducationTypes = {
    PRIMARY: 126560000,
    SECONDARY: 126560001,
    SIXTH_FORM_SCHOOL: 126560002,
    SIXTH_FORM_COLLEGE: 126560003,
    FURTHER_EDUCATION_COLLEGE: 126560004,
    POST_16_TRAINING: 126560005
}
export const GroundsForApplication = {
    DISTANCE: 126560000,
    LOW_INCOME: 126560001,
    MEDICAL_OR_SPECIAL_EDUCATION_NEEDS: 126560002
}
export const MobilityEquipmentTypes = {
    NOT_APPLICABLE: 126560000,
    MANUAL_WHEELCHAIR: 126560001,
    ELECTRIC_WHEELCHAIR: 126560002,
    BUGGY: 126560003,
    WALKING_FRAME: 126560004
}
export const Seat = {
    NOT_APPLICABLE: 126560000,
    JUNIOR_CAR_SEAT: 126560001,
    BOOSTER_SEAT: 126560002,
    BOOSTER_CUSHION: 126560003,
    SPECIAL_CHILD_SEAT: 126560004
}
export const Sex = {
    MALE: "126560000",
    FEMALE: "126560001"
}
export const CourseHours = {
    UNDER_10_HOURS: 126560000,
    _10_TO_15_HOURS: 126560001,
    _16_TO_20_HOURS: 126560002,
    OVER_20_HOURS: 126560003
}