import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { getTransportRequests } from "../../services/Index";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";

const Home = () => {
    const [transportRequests, setTransportRequests] = useState([]);
    const [loading, setLoading] = useState(true)

    const { instance } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        getTransportRequests()
            .then((res) => {
                setLoading(false);
                setTransportRequests(res);
            })
            .catch((error) => {
                localStorage.removeItem("AzureADB2C");
                localStorage.removeItem("customerDetails");

                navigate("/");
                instance.logout();

                toast.error("There is an error. Please re-authenticate and try again.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }, []);

    return (
        <div className="riskassessment-footer">
            <div className="container">
                <div class="mb-3 mt-4">
                    <Link to="/Apply" class="apply-transport-btn ">
                        Apply for Travel Assistance
                    </Link>
                </div>
                {loading && <div className="center">
                    <RotatingLines
                        strokeColor="#4e3782"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />
                </div>}
                {transportRequests.length > 0 &&
                    <div className="table-width-box">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Date Submitted</th>
                                    <th>Travel Assistance Start Date</th>
                                    <th>Travel Assistance End Date</th>
                                    <th>Child / Young Person Name</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transportRequests.map((curr) => (
                                    <tr>
                                        <td>{curr.transportRequestNumber}</td>
                                        <td>{new Date(curr.createdOn).toLocaleDateString('en-GB')}</td>
                                        <td>{new Date(curr.travelAssistanceStartDate).toLocaleDateString('en-GB') === "01/01/1" ? "Not Available" : new Date(curr.travelAssistanceStartDate).toLocaleDateString('en-GB')}</td>
                                        <td>{new Date(curr.travelAssistanceEndDate).toLocaleDateString('en-GB') === "01/01/1" ? "Not Available" : new Date(curr.travelAssistanceEndDate).toLocaleDateString('en-GB')}</td>
                                        <td>{curr.firstName + " " + curr.lastName}</td>
                                        <td className="tr-table-btn">
                                            <button disabled>{curr.status}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            <p>Decisions on travel assistance can take up to 20 days to approve from the submission date, please allow us this time before contacting us.</p>

                            <p><b>For applications requesting travel assistance during the academic year - </b> We will endeavor to get Travel assistance in place as soon as possible, timescales will vary depending on the type of travel assistance. We will be in contact with you within 5 working days to undertake a risk assessment prior to any assistance being put in place, the risk assessment requires detailed information about the child’s health and abilities with regard to travel. It can take up to 20 days to put Travel assistance in place so, you must organize your own travel assistance during this time. Please allow us this time before chasing the Travel assistance progress.</p>

                            <p><b>For applications requesting travel assistance for the new academic year - </b> We will be in contact with you within 30 working days to undertake a risk assessment prior to any assistance being arranged, the risk assessment requires detailed information about the child’s health and abilities with regards travel. We will be in touch before the 31st of August (or sooner) to confirm the travel arrangements. Please allow us this time before chasing the travel assistance progress.</p>
                        </div>
                    </div>}
                {transportRequests.length === 0 && loading === false && <div class="alert alert-info">
                    You have not yet submitted any requests for Travel Assistance.
                </div>}
            </div>
        </div>
    );
};

export default Home;
